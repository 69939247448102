import Head from "next/head";
import MetaTags from "./metatags";
import PropTypes from "prop-types";
import { collapseSpaces } from "../../utils/string";

function GeneralMetaTags({ params }) {
  const commonKW = [];
  const keywords = [].concat(params.keywords, commonKW);
  const commasRE = RegExp(",{2,}", "g");
  let keywordsCSV = keywords
    .join(",")
    .replace(commasRE, ",")
    .replaceAll(" ,", ",")
    .trim(",")
    .replaceAll(",", ", ");

  return (
    <>
      {/* Description in 130 characters */}
      <meta key="description" name="description" content={params.description} />
      <meta key="keyword" name="keywords" content={keywordsCSV} />
    </>
  );
}

function OGMetaTags({ params }) {
  return (
    <>
      <meta key="og-type" property="og:type" content="website" />
      <meta key="og-title" property="og:title" content={params.title} />
      {/* Description in 130 characters */}
      <meta
        key="og-description"
        property="og:description"
        content={params.description}
      />
      <meta key="og-image" property="og:image" content={params.imageURL} />
      <meta key="og-url" property="og:url" content={params.permalink} />
      <meta
        key="og-sitename"
        property="og:site_name"
        content="https://cohyk.com"
      />
    </>
  );
}

function TwitterMetaTags({ params }) {
  return (
    <>
      <meta key="twitter-title" name="twitter:title" content={params.title} />
      {/* Description in 130 characters */}
      <meta
        key="twitter-description"
        name="twitter:description"
        content={params.description}
      />
      <meta
        key="twitter-image"
        name="twitter:image"
        content={params.imageURL}
      />
      <meta key="twitter-site" name="twitter:site" content="@cohyk_travel" />
      <meta
        key="twitter-creator"
        name="twitter:creator"
        content="@cohyk_travel"
      />
    </>
  );
}

function GoogleMetaTags({
  noindex,
  params = { noIndex: false, noFollow: false },
}) {
  const metaContent = [
    params?.noIndex ? "noindex" : "index",
    params?.noFollow ? "nofollow" : "follow",
  ].join(", ");

  return (
    <>
      <meta key="google-notranslate" name="googlebot" content="notranslate" />
      <meta key="robots" name="robots" content={metaContent} />
    </>
  );
}

function getPageTitle(params) {
  const preSpaceCollapse = ["Cohyk |", ...params].join(" ").trim(" ");
  return collapseSpaces(preSpaceCollapse);
}

/**
 * PageHead is for customizing head tag of the page
 *
 * @param pageTitle for setting title for the page
 * @param pageTitleParams for setting title as joined strings
 * @param metaTagParams for setting <meta> tags, use MetaTags class object
 * @returns <head>
 */
export default function PageHead({
  metaTagParams,
  pageTitle = "",
  pageTitleParams = [],
}) {
  pageTitle = getPageTitle([pageTitle, ...pageTitleParams]);
  metaTagParams = metaTagParams || new MetaTags({});
  metaTagParams.title = pageTitle;
  metaTagParams.description = collapseSpaces(metaTagParams.description);
  return (
    <Head>
      <title key="title">{pageTitle}</title>
      <GeneralMetaTags params={metaTagParams} />
      <OGMetaTags params={metaTagParams} />
      <TwitterMetaTags params={metaTagParams} />
      <GoogleMetaTags params={metaTagParams} />
    </Head>
  );
}

PageHead.propTypes = {
  metaTagParams: PropTypes.instanceOf(MetaTags),
  pageTitle: PropTypes.string,
  pageTitleParams: PropTypes.arrayOf(String),
};
