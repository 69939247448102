import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Stack, Button, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRouter } from "next/router";
import styles from "./footer.module.css";
import Image from "next/image";
import logoImg from "../../public/images/logo_white.svg";
import { Twitter } from "@mui/icons-material";
import InstagramIcon from "../../public/images/instagram_light.svg";
import ALink from "../ui/link";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import { captureEvent } from "../google-analytics/tag";
import { useDispatch } from "react-redux";
import SpotifyLightIcon from "../../public/images/icons/spotify_light.svg";
import useIsMobile from "../../utils/device.type.hook";
import { AUTH_TYPE } from "../auth/login";

const FooterItemTypography = styled(Typography)((theme) => ({
  marginBottom: "6px",
  cursor: "pointer",
}));

function FooterCompanyName() {
  return (
    <Stack
      direction="row"
      gap="8px"
      alignItems="center"
      className={styles.company_header}
    >
      <Image
        src={logoImg}
        alt="Cohyk logo in footer"
        className={styles.footer_logo}
        priority
      />
      <Typography className={styles.footer_companyName}>Cohyk 2023</Typography>
    </Stack>
  );
}

function FooterCopyright() {
  return (
    <Typography
      className={styles.footer__copyright}
      variant="text-base"
      color="white"
    >
      &copy; cohyk 2023
    </Typography>
  );
}

function FooterSocialLinkText({ text }) {
  return (
    <Typography className={styles.socialLink_icon__text} variant="text-base">
      {text}
    </Typography>
  );
}

export default function Footer() {
  const dispatch = useDispatch();
  const router = useRouter();
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const userProfile = useSelector((state) => state.AuthReducer.profile);
  const isHostLoggedIn = isLoggedIn && userProfile?.isHost;
  const isMobile = useIsMobile();

  const handleRegisterClick = () => {
    captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.FOOTER);
    dispatch({ type: "OPEN_HOST_REGISTER_MODAL" });
  };
  const handleLoginClick = () =>
    dispatch({
      type: "OPEN_LOGIN_MODAL",
      payload: {
        authType: AUTH_TYPE.REGISTER,
      },
    });

  return (
    <Box className={styles.footer__container}>
      <Container>
        <Box component={"div"}>
          <Stack
            direction="row"
            justifyContent="space-between"
            className={styles.footerStack__container}
          >
            <Stack className={styles.footer_left}>
              {isMobile ? <FooterCopyright /> : <FooterCompanyName />}

              <Stack className={styles.footer_list}>
                <Stack className={styles.footer_list__column}>
                  {isLoggedIn && !isHostLoggedIn && (
                    <FooterItemTypography>
                      <ALink href="/create-host">Host a trip</ALink>
                    </FooterItemTypography>
                  )}
                  {!isLoggedIn && (
                    <FooterItemTypography onClick={handleRegisterClick}>
                      Host a trip
                    </FooterItemTypography>
                  )}
                  <FooterItemTypography>
                    <ALink href="https://www.blog.cohyk.com">Blog</ALink>
                  </FooterItemTypography>
                </Stack>

                <Stack className={styles.footer_list__column}>
                  <FooterItemTypography>
                    <ALink href="/faqs">FAQs</ALink>
                  </FooterItemTypography>
                  <FooterItemTypography>
                    <ALink href="/terms-of-service">Terms of Service</ALink>
                  </FooterItemTypography>
                  <FooterItemTypography>
                    <ALink href="/privacy">Privacy Policy</ALink>
                  </FooterItemTypography>
                  <FooterItemTypography>
                    <ALink href="/cancellation-and-refund">
                      Cancellation & Refund
                    </ALink>
                  </FooterItemTypography>
                </Stack>
                {/* <FooterItemTypography>
                <ALink href="/credits">Credits</ALink>
              </FooterItemTypography> */}
              </Stack>
              {/* <Link href="/about">
              <FooterItemTypography>About</FooterItemTypography>
            </Link> */}
              {/* <Link href="/privacy">
              <FooterItemTypography>Privacy</FooterItemTypography>
            </Link>
            <Link href="/terms">
              <FooterItemTypography>Terms of service</FooterItemTypography>
            </Link> */}
              <Stack
                className={styles.socialMedia__container}
                direction="row"
                gap="19px"
              >
                <ALink
                  href="https://www.instagram.com/cohyk.travel"
                  aProps={{ target: "_blank" }}
                >
                  <Stack
                    direction="row"
                    gap="8px"
                    className={styles.socialLink_item}
                  >
                    <Image
                      src={InstagramIcon}
                      alt={"instagram icon"}
                      priority
                    />
                    <FooterSocialLinkText text="Instagram" />
                  </Stack>
                </ALink>

                {/* <SocialMediaIcon icon={facebook} href="" /> */}
                <ALink
                  href="https://www.twitter.com/cohyk_travel"
                  aProps={{ target: "_blank" }}
                >
                  <Stack
                    direction="row"
                    gap="8px"
                    className={styles.socialLink_item}
                  >
                    <Twitter
                      style={{ color: "#fff" }}
                      className={styles.socialLink_icon}
                      fontSize="small"
                    />
                    <FooterSocialLinkText text="Twitter" />
                  </Stack>
                </ALink>

                <ALink
                  href="https://open.spotify.com/user/31sfs6ei3p4sjhomlgtk5lnui7ma?si=ab86739172ab4809"
                  aProps={{ target: "_blank" }}
                >
                  <Stack
                    direction="row"
                    gap="8px"
                    className={styles.socialLink_item}
                  >
                    <Image
                      className={styles.socialLink_icon}
                      src={SpotifyLightIcon}
                      alt={"spotify icon"}
                      priority
                    />
                    <FooterSocialLinkText text="Spotify" />
                  </Stack>
                </ALink>
                <Typography variant="text-base" className={styles.email_text}>
                  contact@cohyk.com
                </Typography>
              </Stack>
            </Stack>
            <Stack className={styles.footer_right}>
              <Typography variant="text-2xl-semibold">
                Find travelers for trips
              </Typography>
              <Typography variant="text-base-slim" marginTop="12px">
                Cohyk is the best place to promote trips and let travelers find
                you.
              </Typography>
              {!isLoggedIn && (
                <Button
                  className={styles.footerSignin_btn}
                  onClick={handleLoginClick}
                >
                  <Typography
                    variant="text-base-semibold"
                    color="#26a77c"
                    className={styles.footerSignin_btn_text}
                  >
                    Create Account
                  </Typography>
                </Button>
              )}
              <FooterCopyright />
            </Stack>
          </Stack>
        </Box>
        <Box component="div" style={{ marginTop: "30px" }}>
          <Typography
            component={"p"}
            className={styles.footer__disclaimer}
            variant="text-xxs"
          >
            Cohyk is a trip and trip host discovery platform for travellers.
            Travellers can connect with hosts on the platform using Cohyk Chat
            and book their fixed departure trips or can request for personalised
            and customised trips. Travellers can discover trips by moods or
            category, budget range, hosts. size of the group, destinations and
            more. For hosts, Cohyk is a platform to build their social profile
            and showcase their trips to the traveller community. Hosts can
            utilise trip management features to keep the community updated about
            their trips and use Cohyk Chat to engage with travellers connecting
            with them.
            <br />
            <br />
            Disclaimer: Cohyk aims to keep the information about hosts, trip
            host and their trips accurate and updated but Cohyk isn’t
            responsible for any out of date or incorrect information. Before
            booking a trip, traveller must confirm all the details regarding the
            trip with the host including trip dates, booking amount, total
            amount, payment mode, payment dates, cancellation policy. Know that
            cancellations are handled by hosts as per their cancellation policy
            for the trip and hence, Cohyk can only assist with the request.
            Cohyk shall not be responsible. Use Chat feature on the platform to
            keep Cohyk informed and to be able to assist in case of conflicts.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
